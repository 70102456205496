import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { ViewOfferSetUpDto } from "src/app/shared/models/viewoffersetupdto";
import { ProductService } from "src/app/shared/services/product.service";
import { ToastService } from "src/app/shared/services/toastr.service";

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: "app-offer-dialog",
  templateUrl: "./offer-dialog.component.html",
  styleUrls: ["./offer-dialog.component.scss"],
})
export class OfferDialogComponent implements OnInit {
  offer: ViewOfferSetUpDto;
  selectedRadio: boolean;
  displayedColumns: string[] = ["offerName", "offerType", "action"];
  dataSource: any;
  currentUrl: string;

  constructor(
    public dialogRef: MatDialogRef<OfferDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public offerValue: any,
    private formBuilder: UntypedFormBuilder,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private productService: ProductService,
    private navRoute: Router
  ) {
    dialogRef.disableClose = true;
    console.log(this.offerValue)
    if (this.offerValue.appliedOfferId) {
      this.offerValue.offers.filter(e => e.offerId == this.offerValue.appliedOfferId)[0].checked = true;
      this.offer = this.offerValue.offers.find(e => e.offerId == this.offerValue.appliedOfferId);
    } else {
      this.offerValue.offers.forEach(element => element.checked = false);
    }
    this.dataSource = this.offerValue.offers;
    // let appliedOffer: ViewOfferSetUpDto = this.offerValue.offers.filter(e => e.offerId == this.offerValue.appliedOfferId)[0];
    // if (appliedOffer)
    //   this.onChangeOffer(appliedOffer);
  }

  ngOnInit() {
    console.log(this.offerValue);
    this.currentUrl = this.navRoute.url.split('?')[0].replace("/", "");
    console.log(this.currentUrl);

  }

  onChangeOffer(offers: ViewOfferSetUpDto) {
    this.offerValue.offers.filter(e => e.checked = false);
    offers.checked = true;
    console.log(offers);
    console.log(this.offerValue);
    if (this.offerValue.appliedOfferId && offers.offerId == this.offerValue.appliedOfferId && this.currentUrl == 'cart-items')
      this.offer = undefined;
    else
      this.offer = offers;
  }

  onChangeOffer2(event: MatCheckboxChange, offers: ViewOfferSetUpDto) {
    console.log(event.checked)
    if (event.checked) {
      this.offerValue.offers.filter(e => e.checked = false);
      offers.checked = true;
      console.log(offers);
      if (this.offerValue.appliedOfferId && offers.offerId == this.offerValue.appliedOfferId)
        this.offer = undefined;
      else
        this.offer = offers;
    } else {
      this.offer = undefined;
    }
  }
}
