<div style="margin-top: 3%">
  <div class="section-heading">
    <h2 class="h2_font_size">Order History</h2>
    <div class="hr" style="padding: 4px;"></div>
  </div>
</div>

<div id="table-container"
  style="width: 96% !important; overflow: auto; height: auto; padding-top: 2%;margin-left: 2%;margin-right: 2%;">
  <div class="order-container">
    <div *ngFor="let order of dataSource.data; let i = index" class="order-card">
      <div class="order-header">Order No: {{ order.orderNo }}</div>
      <div class="order-content">
        <div class="product-list-names">
          {{ getProductNames(order) }}
        </div>
        <div class="status">Status: {{ order.trackingStatus }}</div>
        <div *ngIf="order.trackingStatus != 'Delivered'" class="status">OTP: <span>&nbsp;{{ order.otp }}</span></div>
        <div class="ratings">
          <span class="rating_span" *ngIf="order.trackingStatus == 'Delivered'">
            Location
            <ngb-rating [formControl]="locationRateValue" [readonly]="order.deliveryFeedBackWebDto?.toLocation > 0"
              [max]="5" (click)="onLocationRateChange(order)" [(rate)]="order.deliveryFeedBackWebDto.toLocation"
              required>
            </ngb-rating>
          </span>
          <span class="rating_span" *ngIf="order.trackingStatus == 'Delivered' && !order.dispatchStorePickupDto">
            Rider
            <ngb-rating [formControl]="deliveryRateValue" [readonly]="order.deliveryFeedBackWebDto?.toDeliveryBoy > 0"
              [max]="5" (click)="onDeliveryRateChange(order)" [(rate)]="order.deliveryFeedBackWebDto.toDeliveryBoy"
              required>
            </ngb-rating>
          </span>
        </div>
      </div>
      <div class="order-tabs">
        <ul>
          <li class="order-tabs-border" [class.selected]="selectedTab[i] == 'order'" (click)="toggleTab(i, 'order')">
            Order</li>
          <li class="order-tabs-border" [class.selected]="selectedTab[i] == 'productDetails'"
            (click)="toggleTab(i, 'productDetails')">Product
          </li>
          <li class="order-tabs-border" [class.selected]="selectedTab[i] == 'shipping'"
            (click)="toggleTab(i, 'shipping')">Shipping</li>
          <li class="order-tabs-border" [class.selected]="selectedTab[i] == 'invoice'"
            (click)="toggleTab(i, 'invoice')">Invoice</li>
          <li [class.selected]="selectedTab[i] == 'status'" (click)="toggleTab(i, 'status'); onStatusClick(order)">
            Status</li>
        </ul>
        <div class="tab-content" *ngIf="selectedTab[i] == 'order'">
          <div class="product-card">
            <div class="product-header">Order Details</div>
            <div class="shipping-details">
              <p><strong>Date:</strong> {{ order.createdDate | date: 'YYYY-MM-dd HH:mm:ss' }}</p>
              <p><strong>Order No:</strong> {{ order.orderNo }}</p>
              <p><strong>Shipping No:</strong> {{ order.shippingDetails?.orderNo }}</p>
              <p><strong>Location:</strong>
                {{order.location?.locationName}},{{order.location?.address}},{{order.location?.pin}}</p>
              <p><strong>Order Amount:</strong> {{ order.totalAmount | currency: 'INR' }}</p>
              <p><strong>Shipping Amount:</strong> {{ (order.shippingDetails?.totalAmount || 0) | currency: 'INR' }}</p>
              <p><strong>Total Amount:</strong> {{ order.totalAmount + order.shippingDetails?.totalAmount | currency:
                'INR' }}</p>
            </div>
          </div>
        </div>
        <div class="tab-content" *ngIf="selectedTab[i] == 'productDetails'">
          <div class="product-card">
            <div class="product-header">Product Details</div>
            <div class="product-list-wrapper">
              <!-- <button class="prev-btn" (click)="prevProduct(i)">&#10094;</button> -->
              <div #productList class="product-list">
                <div *ngFor="let element of order.webOrderDetails" class="product-item-list">
                  <p><strong>Name:</strong> {{ element.productName }}</p>
                  <p><strong>Offer Type:</strong> {{ (element.promotionMaster?.schemeType || 'NA') }}</p>
                  <p><strong>Quantity:</strong> {{ element.qty }}</p>
                  <p><strong>Price:</strong> {{ element.rate | number: '1.2-2' }}</p>
                  <p><strong>Message:</strong> {{ element.message || 'NA' }}</p>
                </div>
              </div>
              <!-- <button class="next-btn" (click)="nextProduct(i)">&#10095;</button> -->
            </div>
          </div>
        </div>
        <div class="tab-content" *ngIf="selectedTab[i] == 'shipping'">
          <div class="product-card">
            <div class="product-header">Shipping Details</div>
            <div class="shipping-details">
              <p><strong>Customer Name:</strong> {{ order.customerDeliveryDetails ?
                order.customerDeliveryDetails?.contactName :
                order.customer?.customerName}}</p>
              <p><strong>Mobile No:</strong> {{ order.customerDeliveryDetails ?
                order.customerDeliveryDetails?.contactMobile :
                order.customer?.customerMobile}}</p>
              <p><strong>Delivery Date:</strong> {{ order.deliveryDate }}</p>
              <p><strong>Delivery Time:</strong> {{ order.deliveryTime }}</p>
              <p><strong>House Address:</strong> {{ order.customerDeliveryDetails?.shippingAddress || 'NA' }}</p>
              <p><strong>Plot No:</strong> {{ order.customerDeliveryDetails?.shippingPlotNo || 'NA' }}</p>
              <p><strong>Area Locality:</strong> {{ order.customerDeliveryDetails?.shippingStreet || 'NA' }}</p>
              <p><strong>City:</strong> {{ order.customerDeliveryDetails?.shippingCity.cityName || 'NA' }}</p>
              <p><strong>Pincode:</strong> {{ order.customerDeliveryDetails?.shippingPinCode || 'NA' }}</p>
            </div>
          </div>
        </div>

        <div class="tab-content" *ngIf="selectedTab[i] == 'invoice'">
          <div class="product-card">
            <div class="product-header">Invoice Details</div>
            <div class="shipping-details">
              <p><strong>Order Invoice:</strong> <a class="inv-details-a" id="invoiceLink" [routerLink]=""
                  (click)="generateInvoice(order.location?.refNo,order.invoiceDetails[0]?.invoiceNo,order.invoiceDetails[0]?.invoiceType)">
                  {{ order.invoiceDetails[0]?.invoiceNo }}
                </a>
              </p>
              <p *ngIf="order.shippingDetails?.invoiceDetails[0]?.invoiceNo">
                <strong>Shipping Invoice:</strong>
                <a class="inv-details-a" id="invoiceLink" [routerLink]=""
                  (click)="generateInvoice(order.location?.refNo,order.shippingDetails?.invoiceDetails[0]?.invoiceNo,order.shippingDetails?.invoiceDetails[0]?.invoiceType)">
                  {{ order.shippingDetails?.invoiceDetails[0]?.invoiceNo }}
                </a>
              </p>
            </div>
          </div>
        </div>
        <div class="tab-content" *ngIf="selectedTab[i] == 'status'">
          <div class="product-card">
            <div class="product-header">Order Status</div>
            <div class="stepper-vertical shipping-details" [id]="'index_step_' + order.orderNo">
              <div class="step" *ngFor="let status of order.statusList; let i = index"
                [class.done]="i < stepperStatus[order.orderNo]" [class.editing]="i === stepperStatus[order.orderNo]">
                <div class="step-circle">
                  <span *ngIf="!(i < stepperStatus[order.orderNo])">{{ i + 1 }}</span>
                </div>
                <div class="step-title">{{ status }}</div>
                <div class="step-bar"></div>
              </div>
            </div>

            <div class="step">
              <button type="button" class="cancel_btn"
                *ngIf="order.trackingStatus == 'Accepted' && order.invoiceDetails[0]?.invoiceType == 'Make To Order'"
                (click)="onOrderCancelClick(order)">Cancel Order</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>